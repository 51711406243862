import { css } from "styled-components"

export const textAndForm = css`

  section { 
    padding-bottom: 240px;

    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
  }

  /* @TODO - Extract this, it's repeated already. */
  .texts {

    display: flex;

    p { 
      font-size: 24px;
      line-height: 34px;
    }

    &--left {
      margin-right: 30px;
      max-width: 552px;
    }

    &--right {
      max-width: 543px;
    }

    @media (max-width: 768px) {

      flex-direction: column;

      p:first-child {
        margin-bottom: 30px;
      }
    }

  }

  .contact-form-section {

    margin-top: 100px;

    p.text {
      font-size: 24px;
    }

    form.hire-us-form {

      margin-top: 20px;

      textarea {
        margin-bottom: 20px;
        border: 1px solid #48E4B7;
        width: 100%;
        padding: 20px;
        /* color: #D8D8D8; */
        box-sizing: border-box;

        &::placeholder {
          color: #D8D8D8;
        }

        ::-webkit-resizer {
          background: linear-gradient(135deg, rgba(255,255,255,1) 50%, rgba(64,64,64,1) 50%);
        }
      }

      #email {
        border: 1px solid #48E4B7;
        padding: 10px 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        display: block;

        &::placeholder {
          color: #D8D8D8;
        }

        ::-webkit-resizer {
          background: linear-gradient(135deg, rgba(255,255,255,1) 50%, rgba(64,64,64,1) 50%);
        }
      }
    }

  }

  .infotext-below-form {
    font-size: 24px;
    line-height: 34px;

    margin-top: 100px;
    margin-bottom: 60px;

    a {
      font-size: 24px;
    }
  }

  .section-title.upwork-section-title {
    margin-bottom: 30px;
  }

  .about-upwork-info {

    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &--list {
      flex-basis: 820px;

      @media (max-width: 768px) {
        order: 2;
      }
    }

    &--logo {
      /* background-color: #6fda44; */
      width: 213px;
      height: 213px;
      flex-shrink: 0;

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
    }

    &__content {

      margin-top: 20px;
      display: flex;

      &__left-rhombus {
        flex-shrink: 0;
        margin-top: 7px;

        background-color: #FCEDBD;
        transform: rotate(45deg);
        width: 21px;
        height: 21px;
        border-radius: 3px;
      }

      &__text {
        margin-left: 20px;

        font-size: 24px;
        line-height: 34px;
      }

    }

  }


`