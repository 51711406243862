import React from "react"

import { MainLayout } from "../../layouts/main.layout"
import StandardPageHeader from "../../components/layout-components/top/headers/standard-page-header/StandardPageHeader.component"
import PageSection from "../../layouts/PageSection/PageSection.component"
import MainCtaButton from "../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"
import Footer from "../../components/layout-components/bottom/footer/Footer.component"
import { getCanonicalUrl } from "../../utils/getCanonicalUrl"

import { textAndForm } from "./css/textAndForm.css"
import { getStaticImgUrl } from "../../utils/getStaticImgUrl"

const meta = {
  title: "Hire Us",
  description: "We started out in 2011 as a ragtag team of English lit majors, gamers, musicians, and free-spirited bloggers working for a local digital marketing company."
}

const og = {
  image: getStaticImgUrl("unsplash-photo-1461988625982-7e46a099bf4f-d.jpeg")
}

const pageSlug = "hire-us";

export default () => (

  <MainLayout meta={meta} og={og} canonicalUrl={getCanonicalUrl(pageSlug)}>
    


    {/* 
    
          Header

    */}

    <StandardPageHeader 
      data={
        {
          heading: "Hire Us",
          backgroundImage: "/unsplash-photo-1486312338219-ce68d2c6f44d-d.jpeg"
        }
      } 
    />


    
    {/* 
    
          "Hire Us" text & form

    */}

    <PageSection extendStyles={textAndForm}>

      <div className="texts">
        <p className="texts--left">Use the <strong>message box</strong> below to tell us what you need and we’ll get back to you with a quote. If we need more details to come up with an accurate quote, we’ll ask you about it and iron things out before we begin a project.</p>
        <p className="texts--right">Try to be as precise as possible about your project so that we can speed things along. We have an article on the <u>“6 Tips on Communicating Your Writing Project to a Freelancer”</u> that will help you create an awesome project description that ensures minimal back-and-forth and reduces the chances of any misunderstandings.</p>
      </div>

      <div className="contact-form-section">

        <p className="text bold">Tell us about your project</p>

        <form 
          name="hire-us"
          action="/thanks/"
          method="post" 
          className="hire-us-form"
          data-netlify="true" 
          data-netlify-honeypot="bot-field"
          >

            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="hire-us" />

            <textarea 
              id="message"
              name="message"
              rows={5}
              placeholder="Something briefly"
            >
            </textarea>

            <input 
              id="email" 
              type="email" 
              name="email" 
              placeholder="Your e-mail address ..." />

            <MainCtaButton buttonText="Send" netlifyForm={true} />

        </form>

      </div>

      <p className="infotext-below-form">If you prefer, you can shoot us an email directly at <u><a href="mailto:contact@podroomcreative.com">contact@podroomcreative.com</a></u>. We like to start a conversation through email, as its easier to attach document and keep track of everything later on. <br /><br />If you’d like to have a <strong>Skype</strong> call, hit us up with an email first – lay out the basics of your project in a few lines and leave your Skype information, and well get back to you in a short while. <br /><br />Alternatively, you can hire us through <u><a href="https://www.upwork.com/o/companies/~01258ca8260faa5fe5/">UpWork</a></u>. Why go through the hassle of setting up a project on UpWork? Here are a few good reasons:</p>

      <h2 className="section-title upwork-section-title">Upwork</h2>

      <div className="about-upwork-info">

        <div className="about-upwork-info--list">

          <div className="about-upwork-info__content">
            <div className="about-upwork-info__content__left-rhombus"></div>
            <p className="about-upwork-info__content__text">UpWork has an excellent escrow system that ensures that both the client and the freelancer honor their end of the deal before any money changes hands.</p>
          </div>

          <div className="about-upwork-info__content">
            <div className="about-upwork-info__content__left-rhombus"></div>
            <p className="about-upwork-info__content__text">When we bring a client to the platform through a referral link, there are 0% fees involved, so you don’t have to worry about spending a lot of extra money.</p>
          </div>

          <div className="about-upwork-info__content">
            <div className="about-upwork-info__content__left-rhombus"></div>
            <p className="about-upwork-info__content__text">It’s incredibly easy to set up projects, both one-time and long-term weekly or monthly, fixed price or hourly, and some people prefer this system to email exchanges and contracts.</p>
          </div>

        </div>

        <div className="about-upwork-info--logo">
          <img src="/upwork-logo.png" />
        </div>

      </div>

    </PageSection>



    {/* 

          Footer
    
    */}

    <Footer />

  </MainLayout>
      
)